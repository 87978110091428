var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'v-select',
  { 'v-select-grid': _vm.label.length }
]},[(_vm.label.length)?_c('label',{staticClass:"v-select__label"},[_vm._v(" "+_vm._s(_vm.$t(("" + _vm.label)))+" ")]):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.blurSelect),expression:"blurSelect"}],staticClass:"v-select__content"},[_c('div',{staticClass:"v-select__selected-item",on:{"click":_vm.focusSelect}},[_c('span',{class:[
        'v-select__selected-item-text',
        { 'v-select__selected-item-placeholder': !_vm.selectedItem }
      ]},[_vm._v(" "+_vm._s(_vm.$t(("" + _vm.selectedItem)) || _vm.$t(("" + _vm.placeholder)))+" ")]),_c('IconArrowDown',{class:[
        'v-select__selected-item-icon',
        { 'v-select__selected-item-icon_active': _vm.selectIsActive }
      ]})],1),(_vm.selectIsActive)?_c('div',{staticClass:"v-select__options-items"},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"v-select__options-item",on:{"click":function($event){return _vm.selectOptionItem(option)}}},[_vm._v(" "+_vm._s(_vm.$t(("" + option)))+" ")])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }