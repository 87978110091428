<template>
  <div :class="[
    'v-select',
    { 'v-select-grid': label.length }
  ]">
    <label
      v-if="label.length"
      class="v-select__label"
    >
      {{ $t(`${label}`) }}
    </label>

    <div
      class="v-select__content"
      v-click-outside="blurSelect"
    >
      <div
        class="v-select__selected-item"
        @click="focusSelect"
      >
        <span :class="[
          'v-select__selected-item-text',
          { 'v-select__selected-item-placeholder': !selectedItem }
        ]">
          {{ $t(`${selectedItem}`) || $t(`${placeholder}`) }}
        </span>

        <IconArrowDown :class="[
          'v-select__selected-item-icon',
          { 'v-select__selected-item-icon_active': selectIsActive }
        ]" />
      </div>

      <div
        v-if="selectIsActive"
        class="v-select__options-items"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="v-select__options-item"
          @click="selectOptionItem(option)"
        >
          {{ $t(`${option}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowDown from '@/assets/icons/arrow-down.svg';

export default {
  name: 'VInputSelectUI',
  components: {
    IconArrowDown,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectIsActive: false,
    selectedItem: '',
  }),

  methods: {
    focusSelect() {
      this.selectIsActive = true;
    },
    blurSelect() {
      this.selectIsActive = false;
    },
    selectOptionItem(value) {
      this.selectedItem = value;
      this.$emit('select-item', this.$t(`${value}`));
      this.blurSelect();
    },
  },

  i18n: {
    messages: {
      'en': {
        'I am a business owner': 'I am a business owner',
        'I am the team leader': 'I am the team leader',
        'I am the team member': 'I am the team member',
        'I am a freelancer': 'I am a freelancer',
        'I am a Director/top Manager': 'I am a Director/top Manager',
        'Design': 'Design',
        'Marketing': 'Marketing',
        'HR': 'HR',
        'Sales': 'Sales',
        'Product creation': 'Product creation',
        'Project management': 'Project management',
        'Organization of events': 'Organization of events',
        'Other': 'Other',
      },
      'ru': {
        'I am a business owner': 'Я бизнесмен',
        'I am the team leader': 'Я лидер команды',
        'I am the team member': 'Я участник команды',
        'I am a freelancer': 'Я фрилансер',
        'I am a Director/top Manager': 'Я директор/топ-менеджер',
        'Design': 'Дизайн',
        'Marketing': 'Маркетинг',
        'HR': 'HR',
        'Sales': 'Продажи',
        'Product creation': 'Создание продукта',
        'Project management': 'Управление проектами',
        'Organization of events': 'Организация мероприятий',
        'Other': 'Другое',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-select {
  border: none;
}

.v-select-grid {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 4px;
}

.v-select__label {
  font-size: 12px;
  font-weight: 500;
  color: #1E1E1E;
}

.v-select__content {
  position: relative;
}

.v-select__selected-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 9px 0 11px;
  height: 40px;
  width: 100%;
  border: 1px solid $borderColor;
  border-radius: 4px;
  cursor: pointer
}

.v-select__selected-item-placeholder {
  color: #CECECE;
}

.v-select__selected-item-icon {
  position: absolute;
  right: 9px;
  pointer-events: none;
}

.v-select__selected-item-icon_active {
  transform: rotateX(180deg);
}

.v-select__options-items {
  position: absolute;
  top: 48px;
  padding: 8px 0;
  width: 100%;
  background-color: #FFF;
  box-shadow: 1px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1;
}

.v-select__options-item {
  padding: 9px 0 10px 17px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.7px;
  color: #000;
  cursor: pointer;
}

.v-select__options-item:hover {
  background-color: $lightThemeBackground2;
}
</style>
